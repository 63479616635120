import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'rpp-label-form-error',
  standalone: true,
  imports: [CommonModule],
  template: `<span class="text-red-500">{{ label }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RppLabelFormErrorComponent {
  @HostBinding('class') class = 'block';
  @Input() label: string = '';
}
